import axios from "axios";
import {all, fork, takeLatest, call, put} from 'redux-saga/effects';
import {LOAD_SETTING_FAILURE, LOAD_SETTING_REQUEST, LOAD_SETTING_SUCCESS,} from "./constants";

function loadSettingAPI() {
    return axios.get('/setting');
}

function* loadSetting(action) {
    try {
        const result = yield call(loadSettingAPI, action.data);
        yield put({
            type: LOAD_SETTING_SUCCESS,
            data: result.data.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_SETTING_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadSetting() {
    yield takeLatest(LOAD_SETTING_REQUEST, loadSetting);
}

export default function* userSaga() {
    yield all([
        fork(watchLoadSetting),
    ])
}

