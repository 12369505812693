import {shallowEqual, useSelector} from "react-redux";
import Label from "../../atoms/label";
import styled from "styled-components";
import Button from "../../atoms/button";
import {useCallback, useEffect, useState} from "react";
import QuestionHeader from "../../oraganisms/question/questionHeader";
import LocalStorage from "../../../utils/common/localStorage";

const Question2 = ({onCompletePageMove}) => {
    const question = useSelector(((state) => state.question.question), shallowEqual);
    const [userAnswer, setUserAnswer] = useState({});
    const [mainColor, setMainColor] = useState(LocalStorage.getItem('mainColor') || 'yellow');
    const [title, setTitle] = useState([]);
    const [answer, setAnswer] = useState([]);
    const [fail, setFail] = useState(true);
    const [score, setScore] = useState(100);
    const [count, setCount] = useState(0);
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0, target = null;

    const onCardTouchStart = (e) => {
        pos3 = e.touches[0].clientX;
        pos4 = e.touches[0].clientY;

        e.target.ontouchmove = onCardTouchMove;
    };

    const onCardMouseStart = (e) => {
        pos3 = e.clientX;
        pos4 = e.clientY;
        target = e;

        e.target.onmouseup = onCardMouseEnd;
        document.addEventListener('mousemove', onCardMouseMove)
    };

    const onCardTouchMove = (e) => {
        e.target.style.position = 'absolute';
        const el = e.target;

        pos1 = (pos3 - e.touches[0].clientX);
        pos2 = (pos4 - e.touches[0].clientY);
        pos3 = e.touches[0].clientX;
        pos4 = e.touches[0].clientY;

        el.style.top = (el.offsetTop - pos2) + "px";
        el.style.left = (el.offsetLeft - pos1) + "px";
    };

    const onCardMouseMove = (event) => {
        const e = target;
        pos1 = pos3 - event.clientX;
        pos2 = pos4 - event.clientY;
        pos3 = event.clientX;
        pos4 = event.clientY;

        const el = e.target;
        el.style.top = (el.offsetTop - pos2) + "px";
        el.style.left = (el.offsetLeft - pos1) + "px";
    };

    const onCardMouseEnd = (e) => {
        pos3 = e.clientX;
        pos4 = e.clientY;

        document.removeEventListener('mousemove', onCardMouseMove);
    };

    const splitTitle = useCallback(() => {
        const titles = question.question[count].titleEn.trim().split(' ');
        const blankCount = question.question[count].blankCount > titles.length ? titles.length : question.question[count].blankCount;
        const answers = [];

        for (let i = 0; i < blankCount; i++) {
            let random = Math.floor(Math.random() * titles.length);

            while (true) {
                if (Array.isArray(titles[random])) {
                    random = Math.floor(Math.random() * titles.length);
                } else {
                    break;
                }
            }

            answers.push(titles[random]);
            titles[random] = ['${blank}', titles[random]];
        }

        setAnswer(answers);
        setTitle(titles);
    }, [count]);

    useEffect(() => {
        splitTitle();
    }, [count]);

    const onSubmit = useCallback(() => {
        const blankEl = document.querySelectorAll('[name=blank]');
        const blankData = [];
        const answerEl = document.querySelectorAll('[name=answerCard]');
        const answerData = [];

        blankEl.forEach((el) => {
            blankData.push({
                value: el.getAttribute('value'),
                x: el.getBoundingClientRect().x,
                y: el.getBoundingClientRect().y,
            });
        });

        answerEl.forEach((el) => {
            answerData.push({
                value: el.innerHTML,
                x: el.getBoundingClientRect().x,
                y: el.getBoundingClientRect().y,
            });
        });

        const result = blankData.map((blank) => {
            let stop = false;

            answerData.forEach((answer) => {
                if (blank.value.trim() === answer.value.trim()) {
                    if (blank.x - 10 < answer.x && answer.x < blank.x + 10) {
                        if (blank.y - 10 < answer.y && answer.y < blank.y + 10) {
                            stop = true;
                        }
                    }
                }
            });

            return stop;
        });

        if (result.filter((v) => !v).length === 0) {
            if ((count + 1) === question.question.length) {
                onCompletePageMove(score);
            } else {
                setCount(count + 1);
            }
        } else {
            setFail(false);
            setMainColor('orange');
            const time = setTimeout(() => {
                setMainColor('yellow');
                setFail(true);
                clearTimeout(time);
            }, 1000);

            setScore(score - 1);
        }

        onRefreshCard();
    }, [userAnswer, answer, score, count]);

    const onRefreshCard = useCallback(() => {
        const el = document.querySelectorAll('[name=answerCard]');
        el.forEach((v) => {
            v.style.position = 'absolute';
            v.style.top = '';
            v.style.left = '';
        });
    }, []);

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', onCardMouseMove);
        }
    }, []);

    return (
        <Wrap>
            <QuestionHeader audioText={question.question.titleEn}/>
            <QuestionMainWrap>
                <QuestionCard>
                    <Label fontSize={'18px'} fontWeight={'bold'} display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
                        {
                            title.map((v, index) => Array.isArray(v) ?
                                <BlankWrap key={index} name={'blank'} value={v[1]} /> : ` ${v} `)
                        }
                    </Label>

                    <Label fontSize={'15px'} color={'#bbb'} margin={'5px 0 0 0'}>{question.question[count].titleKr}</Label>
                    <Label fontSize={'14px'} color={'#bbb'} margin={'10px 0'}> {count + 1} / { question.question.length }</Label>
                </QuestionCard>

                <QuestionAnswerCardWrap>
                    {
                        answer.map((v, index) => <QuestionAnswerCard onTouchStart={onCardTouchStart} onMouseDown={onCardMouseStart} name={'answerCard'} key={index} index={index}> {v} </QuestionAnswerCard>)
                    }
                </QuestionAnswerCardWrap>

                <ButtonWrap className={!fail && 'shake'} size={'middle'} color={mainColor} fontSize={'18px'} margin={'auto 0 0 0'}
                        onClick={onSubmit}>NEXT</ButtonWrap>
            </QuestionMainWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const QuestionMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  overflow: hidden;
`;

const QuestionCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  height: 500px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;

  & input, label {
    font-size: 24px;
  }

  @media screen and (max-width: 768px) {
    height: 335px;

    & input, label {
      font-size: 14px;
    }
  }
`;

const QuestionAnswerCardWrap = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  height: 90px;
  max-width: 1080px;
  width: 100%;
  margin: 20px auto 0 auto;
`;

const QuestionAnswerCard = styled.div`
  position: absolute;
  background-color: #fff;
  border-radius: 6px;
  padding: 0 5px;
  width: 10vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  left: ${({index}) => `calc(${(11 * (index % 6))}vw)`};
  top: ${({index}) => `${(50 * (index < 6 ? 0 : 1))}px`};
  font-size: 24px;
  cursor: pointer;
  
  &.fail {
    position: unset !important;
    transition: all 0.2s;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    width: 29vw;
    height: 40px;
    left: ${({index}) => `calc(${(30 * (index % 3))}vw)`};
    top: ${({index}) => `${(50 * (index < 3 ? 0 : 1))}px`};
  }
`;

const BlankWrap = styled.div`
  display: inline-flex;
  margin: 5px;
  width: 10vw;
  height: 50px;
  background-color: #eee;
  border-radius: 6px;
  box-shadow: inset rgb(0 0 0 / 15%) 0 0 6px 0;

  @media screen and (max-width: 768px) {
    width: 29vw;
    height: 40px;
  }
`

const ButtonWrap = styled(Button)`
  font-size: 18px;
  max-width: 1080px;
  width: 100%;
  margin: 20px auto 0 auto;
  
  &.shake {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0); 
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`

export default Question2;
