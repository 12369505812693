const MobileApp = {
    isMobileWeb: () => window.navigator.userAgent.indexOf("Android") >= 0
        || window.navigator.userAgent.indexOf("iPhone") >= 0
        || window.navigator.userAgent.indexOf("iPad") >= 0,
    isPC: () => window.navigator.platform.indexOf("Win") >= 0 || window.navigator.platform.indexOf("Macintosh") >= 0,
    isIOS: () => (window.navigator.userAgent.indexOf("iPhone") >= 0
        || window.navigator.userAgent.indexOf("iPad") >= 0) && !MobileApp.isPC(),
    isAndroid: () => window.navigator.userAgent.indexOf("Android") >= 0 && !MobileApp.isPC(),
}

export default MobileApp;