import axios from "axios";
import {all, fork, takeLatest, call, put} from 'redux-saga/effects';
import {
    LEVEL_UP_FAILURE,
    LEVEL_UP_REQUEST,
    LEVEL_UP_SUCCESS,
} from "./constants";

function levelUpAPI(data) {
    return axios.put('/question/levelup', data)
}

function* levelUp(action) {
    try {
        const result = yield call(levelUpAPI, action.data);
        yield put({
            type: LEVEL_UP_SUCCESS,
            data: result
        });
    } catch (err) {
        yield put({
            type: LEVEL_UP_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLevelUp() {
    yield takeLatest(LEVEL_UP_REQUEST, levelUp);
}

export default function* userSaga() {
    yield all([
        fork(watchLevelUp),
    ])
}

