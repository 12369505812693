import logo from '../../../assets/images/logo.png';
import Button from "../../atoms/button";
import styled from "styled-components";
import Label from "../../atoms/label";
import history from "../../../utils/history";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Icon from "../../atoms/icon";
import {useCallback, useEffect, useState} from "react";
import LocalStorage from "../../../utils/common/localStorage";
import AvatarUpload from "../../molecules/avatarUpload";
import {TOKEN_KEY_NAME} from "../../../utils/common/constants";
import {loadSettingRequestAction} from "../../../redux/setting/reducer";

const HomeMobileTemplate = () => {
    const me = useSelector(((state) => state.login.me), shallowEqual);
    const dispatch = useDispatch();
    const [mainColor, setMainColor] = useState(LocalStorage.getItem('mainColor') || 'yellow');

    useEffect(() => {
        if (!me.level) history.push('/login');
    }, [me]);

    const onTestExam = useCallback(() => {
        history.push(`/question?level=${me.testLevel}&unit=1&manual=true`);
    }, [me.testLevel, me.unit]);

    const onExam = useCallback(() => {
        history.push(`/question?level=${me.level}&unit=${me.unit}&manual=false`);
    }, [me.level, me.unit]);

    const onChangeColor = useCallback(() => {
        const tempColor = mainColor === 'yellow' ? 'navy' : 'yellow';
        setMainColor(tempColor);
        LocalStorage.setItem('mainColor', tempColor);
    }, [mainColor]);

    const onLogout = useCallback(() => {
        LocalStorage.removeItem(TOKEN_KEY_NAME);
        history.push('/login');
    }, []);

    const loadSetting = useCallback(() => {
        dispatch(loadSettingRequestAction());
    }, []);

    useEffect(() => {
        loadSetting();
    }, []);

    if (me.name) return (
        <Wrap>
            <Background color={mainColor}/>
            <Logo src={logo} alt={'logo'} />
            <Card align={'center'}>
                <AvatarUpload alt={'avatar'} />
                <Label fontSize={'24px'} fontWeight={'bold'} margin={'20px 0 0 0'}>{me.name}</Label>
                <Label fontSize={'13px'} color={'#aaa'}>{me.class}</Label>
                <MainCardSubButtonWrap>
                    <Button shape={'oval'} size={'small'} color={mainColor} fontSize={'14px'} maxWidth={'80px'} maxHeight={'30px'} onClick={onChangeColor}>COLOR</Button>
                    <Button shape={'oval'} size={'small'} color={mainColor} fontSize={'14px'} maxWidth={'80px'} maxHeight={'30px'} onClick={onLogout}>LOGOUT</Button>
                </MainCardSubButtonWrap>
            </Card>

            <CardWrap>
                <Card align={'start'}>
                    <Label fontWeight={'600'}>State</Label>
                    <Label fontSize={'24px'} fontWeight={'bold'} color={'rgb(244, 132, 98)'}>
                        <p>{me.level}</p>
                        <p>Unit.{me.unit}</p>
                        <SmallWrap>단계까지 완료</SmallWrap>
                    </Label>
                    <Icon width={'70px'} height={'70px'} margin={'0 0 0 auto'} background={'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'70\' height=\'70\' viewBox=\'0 0 70 70\'%3e%3cpath data-name=\'사각형 163\' style=\'fill:transparent\' d=\'M0 0h70v70H0z\'/%3e%3cg data-name=\'그룹 49\'%3e%3cpath data-name=\'패스 566\' d=\'M68.292 443.192h41.724v6.536H68.292z\' transform=\'translate(-54.154 -387.935)\' style=\'fill:%23fff59b\'/%3e%3cg data-name=\'그룹 48\'%3e%3cpath data-name=\'패스 567\' d=\'m158 20.255-6.65 3.045a.893.893 0 0 1-1.26-.914l.839-7.258-4.949-5.379a.893.893 0 0 1 .481-1.48l7.168-1.445 3.591-6.37a.894.894 0 0 1 1.557 0l3.59 6.365 7.168 1.445a.893.893 0 0 1 .481 1.48l-4.949 5.379.839 7.258a.893.893 0 0 1-1.26.914z\' transform=\'translate(-122.999 6)\' style=\'fill:%23fff59b\'/%3e%3c/g%3e%3c/g%3e%3cg data-name=\'그룹 50\'%3e%3cpath data-name=\'패스 568\' d=\'M79.951 273.9h-1.915v-14.866a1.111 1.111 0 0 0-1.111-1.111H64.881v-10.188a1.111 1.111 0 0 0-1.111-1.111H48.354a1.111 1.111 0 0 0-1.111 1.111v5.665H35.2a1.111 1.111 0 0 0-1.111 1.111V273.9h-1.916a1.111 1.111 0 0 0 0 2.223h47.778a1.111 1.111 0 0 0 0-2.223zm-43.639-18.27h10.931v18.27H36.311zm13.154-6.779h13.192V273.9H49.466zM64.881 273.9v-13.755h10.932V273.9z\' transform=\'translate(-21.062 -213.214)\' style=\'fill:%23ffd77b\'/%3e%3cpath data-name=\'패스 569\' d=\'M247.111 337.226a1.111 1.111 0 0 0-1.111 1.111v3.99a1.111 1.111 0 1 0 2.223 0v-3.99a1.111 1.111 0 0 0-1.112-1.111z\' transform=\'translate(-212.111 -293.746)\' style=\'fill:%23ffd77b\'/%3e%3c/g%3e%3c/svg%3e ")'}/>
                </Card>

                <Card align={'start'}>
                    <Label fontWeight={600}>Point</Label>
                    <Label fontSize={'24px'} fontWeight={'bold'} color={'rgb(244, 132, 98)'}>{me.point.toLocaleString()}<br/></Label>
                    <Icon width={'70px'} height={'70px'} margin={'0 0 0 auto'} background={'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'70\' height=\'70\' viewBox=\'0 0 70 70\'%3e%3cpath data-name=\'사각형 164\' style=\'fill:transparent\' d=\'M0 0h70v70H0z\'/%3e%3cg data-name=\'그룹 52\' transform=\'translate(-148 -449)\'%3e%3cg data-name=\'타원 20\' transform=\'translate(159 463)\' style=\'stroke-width:2px;stroke:%23ffd77b;fill:none\'%3e%3ccircle cx=\'25\' cy=\'25\' r=\'25\' style=\'stroke:none\'/%3e%3ccircle cx=\'25\' cy=\'25\' r=\'24\' style=\'fill:none\'/%3e%3c/g%3e%3ccircle data-name=\'타원 23\' cx=\'6.5\' cy=\'6.5\' r=\'6.5\' transform=\'translate(203 455)\' style=\'fill:%23fff59b\'/%3e%3cg data-name=\'타원 21\' transform=\'translate(165 469)\' style=\'stroke-width:2px;stroke:%23ffd77b;fill:none\'%3e%3ccircle cx=\'19\' cy=\'19\' r=\'19\' style=\'stroke:none\'/%3e%3ccircle cx=\'19\' cy=\'19\' r=\'18\' style=\'fill:none\'/%3e%3c/g%3e%3ccircle data-name=\'타원 22\' cx=\'15\' cy=\'15\' r=\'15\' transform=\'translate(169 473)\' style=\'fill:%23fff59b\'/%3e%3cg data-name=\'그룹 51\'%3e%3cpath data-name=\'패스 570\' d=\'M2385.5 2267.917v-15\' transform=\'translate(-2205.5 -1771.917)\' style=\'stroke-linecap:round;stroke:%23ffc94e;stroke-width:2px;fill:none\'/%3e%3cg data-name=\'사각형 37\' style=\'stroke:%23ffc94e;stroke-width:2px;fill:none\'%3e%3cpath d=\'M1 0h6a5 5 0 0 1 5 5 5 5 0 0 1-5 5H0V1a1 1 0 0 1 1-1z\' style=\'stroke:none\' transform=\'translate(179 480)\'/%3e%3cpath d=\'M1 1h6a4 4 0 0 1 4 4 4 4 0 0 1-4 4H1V1z\' style=\'fill:none\' transform=\'translate(179 480)\'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e ")'}/>
                </Card>
            </CardWrap>

            <ButtonWrap>
                <Button size={'middle'} color={mainColor} fontSize={'18px'} minHeight={'60px'} onClick={onTestExam}>시험대비</Button>
                <Button size={'middle'} color={mainColor} fontSize={'18px'} minHeight={'60px'} onClick={onExam}>START</Button>
            </ButtonWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  background-color: #f9f9f9;
  overflow-y: auto;
`;

const Logo = styled.img`
  position: relative;
  z-index: 1;
  width: 120px;
  margin: 0 auto 50px auto;
`;

const Background = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 282px;
  background-color: ${({color}) => {
    switch (color) {
      case 'yellow':
        return 'rgb(247, 204, 105)';
      case 'navy':
        return '#4169E1FF';
    }
  }}
`;

const Card = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${({align}) => align};
  justify-content: space-between;
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  gap: 15px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  
  & img {
    position: absolute;
    left: calc(50% - 47px);
    top: -45px;
  }
`;

const CardWrap = styled.div`
  display: flex;
  gap: 15px;
`;

const ButtonWrap = styled.div`
  display: flex;
  gap: 15px;
`

const MainCardSubButtonWrap = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const SmallWrap = styled.p`
  font-size: 16px;
`

export default HomeMobileTemplate;
