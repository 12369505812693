import produce from "immer";
import {
    LOAD_QUESTION_FAILURE,
    LOAD_QUESTION_LEVEL_LIST_FAILURE,
    LOAD_QUESTION_LEVEL_LIST_REQUEST,
    LOAD_QUESTION_LEVEL_LIST_SUCCESS,
    LOAD_QUESTION_MAX_UNIT_COUNT_FAILURE,
    LOAD_QUESTION_MAX_UNIT_COUNT_REQUEST,
    LOAD_QUESTION_MAX_UNIT_COUNT_SUCCESS,
    LOAD_QUESTION_REQUEST,
    LOAD_QUESTION_SUCCESS
} from "./constants";

const initialState = {
    question: {},
    maxUnitCount: 0,
    levelList: [],
    loadQuestionLoading: false,
    loadQuestionDone: false,
    loadQuestionError: false,
    loadQuestionMaxUnitCountLoading: false,
    loadQuestionMaxUnitCountDone: false,
    loadQuestionMaxUnitCountError: false,
    loadQuestionLevelListLoading: false,
    loadQuestionLevelListDone: false,
    loadQuestionLevelListError: false,
}

export const loadQuestionRequestAction = (data) => {
    return {
        type: LOAD_QUESTION_REQUEST,
        data,
    }
}

export const loadQuestionMaxUnitCountRequestAction = (data) => {
    return {
        type: LOAD_QUESTION_MAX_UNIT_COUNT_REQUEST,
        data,
    }
}

export const loadQuestionLevelListRequestAction = (data) => {
    return {
        type: LOAD_QUESTION_LEVEL_LIST_REQUEST,
        data,
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOAD_QUESTION_REQUEST:
            draft.loadQuestionLoading = true;
            draft.loadQuestionDone = false;
            draft.loadQuestionError = null;
            draft.question = {};
            break;
        case LOAD_QUESTION_SUCCESS:
            draft.loadQuestionLoading = false;
            draft.loadQuestionDone = true;
            draft.question = {
                ...action.data,
                maxUnitCount: draft.question.maxUnitCount
            };
            break;
        case LOAD_QUESTION_FAILURE:
            draft.loadQuestionLoading = false;
            draft.loadQuestionDone = false;
            draft.loadQuestionError = action.data;
            break;
        case LOAD_QUESTION_MAX_UNIT_COUNT_REQUEST:
            draft.loadQuestionMaxUnitCountLoading = true;
            draft.loadQuestionMaxUnitCountDone = false;
            draft.loadQuestionMaxUnitCountError = null;
            break;
        case LOAD_QUESTION_MAX_UNIT_COUNT_SUCCESS:
            draft.loadQuestionMaxUnitCountLoading = false;
            draft.loadQuestionMaxUnitCountDone = true;
            draft.question.maxUnitCount = action.data;
            break;
        case LOAD_QUESTION_MAX_UNIT_COUNT_FAILURE:
            draft.loadQuestionMaxUnitCountLoading = false;
            draft.loadQuestionMaxUnitCountDone = false;
            draft.loadQuestionMaxUnitCountError = action.data;
            break;
        case LOAD_QUESTION_LEVEL_LIST_REQUEST:
            draft.loadQuestionLevelListLoading = true;
            draft.loadQuestionLevelListDone = false;
            draft.loadQuestionLevelListError = null;
            break;
        case LOAD_QUESTION_LEVEL_LIST_SUCCESS:
            draft.loadQuestionLevelListLoading = false;
            draft.loadQuestionLevelListDone = true;
            draft.question.levelList = action.data;
            break;
        case LOAD_QUESTION_LEVEL_LIST_FAILURE:
            draft.loadQuestionLevelListLoading = false;
            draft.loadQuestionLevelListDone = false;
            draft.loadQuestionLevelListError = action.data;
            break;
    }
});

export default reducer;

