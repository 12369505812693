import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from "./pages/app";
import {Provider} from "react-redux";
import configureStore from "./configureStore";
import axios from "axios";

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://api.msals.co.kr' : 'http://localhost:4000';
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
    <Provider store={configureStore}>
        <App />
    </Provider>,
);
