import styled from "styled-components";
import QuestionCompleteTemplate from "../../../components/templates/question/questionComplete";
import {shallowEqual, useSelector} from "react-redux";

const QuestionComplete = () => {
    return (
        <Wrap>
            <QuestionCompleteTemplate />
        </Wrap>
    );
}

const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgb(247, 204, 105);
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default QuestionComplete;
