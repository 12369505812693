import {Polly} from "@aws-sdk/client-polly";
import {fromCognitoIdentityPool} from "@aws-sdk/credential-provider-cognito-identity";
import {CognitoIdentityClient} from "@aws-sdk/client-cognito-identity";
import {getSynthesizeSpeechUrl} from "@aws-sdk/polly-request-presigner";

const client = new Polly({
    region: "ap-northeast-2",
    credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({region: "ap-northeast-2"}),
        identityPoolId: "ap-northeast-2:91626112-1d72-4109-88a8-e1917657e028" // IDENTITY_POOL_ID
    }),
});

let audio = null;
const AwsPolly = {
    startSpeak: async ({text, speed, sex}) => {
        try {
            const url = await getSynthesizeSpeechUrl({
                client, params: {
                    OutputFormat: "mp3",
                    SampleRate: "16000",
                    Text: text,
                    TextType: "text",
                    VoiceId: sex ? "Joanna" : "Matthew"
                },
            });

            if (!audio) {
                audio = new Audio(url);
                if (speed) audio.playbackRate = speed;
                let playCount = 0;
                const maxPlayCount = 2; // 최대 재생 횟수
                const playAudio = () => {
                    if (playCount < maxPlayCount) {
                        audio.play();
                        playCount++;

                        audio.onended = () => {
                            setTimeout(playAudio, 500);
                        };
                    } else {
                        audio = null;
                    }
                };
                playAudio();
            } else {
                audio.pause();
                audio = null;
            }
        } catch (err) {
            console.log("Error", err);
        }
    },
    stopSpeak: () => {
        if (audio) {
            audio.pause();
            audio = null;
        }
    }
}

export default AwsPolly;
