import QuestionHeader from "../../oraganisms/question/questionHeader";
import {shallowEqual, useSelector} from "react-redux";
import styled from "styled-components";
import {useCallback, useEffect, useMemo, useState} from "react";
import dropSoundFile from "../../../assets/sounds/drop.mp3";
import failSoundFile from "../../../assets/sounds/fail.wav";
import Button from "../../atoms/button";
import LocalStorage from "../../../utils/common/localStorage";
import awsPolly from "../../../utils/awsPolly";
import MobileApp from "../../../utils/common/mobileApp";

const Question1 = ({onCompletePageMove}) => {
    const question = useSelector(((state) => state.question.question), shallowEqual);
    const [sortTop, setSortTop] = useState([]);
    const [first, setFirst] = useState(true);
    const [count, setCount] = useState(0);
    const [score, setScore] = useState(100);
    const [init, setInit] = useState(true);
    const [sliceQuestion, setSliceQuestion] = useState([]);
    const [success, setSuccess] = useState([]);
    const dropSound = useMemo(() => new Audio(dropSoundFile), []);
    const failSound = useMemo(() => new Audio(failSoundFile), []);
    const [mainColor, setMainColor] = useState(LocalStorage.getItem('mainColor') || 'yellow');
    const [changeSex, setChangeSex] = useState(false);
    const cardHeight = MobileApp.isMobileWeb() ? 45 : 65;
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0, target = null;

    const onCardTouchStart = (e) => {
        setInit(false);
        pos3 = e.clientX;
        pos4 = e.clientY;

        e.target.ontouchend = onCardTouchEnd;
        e.target.ontouchmove = onCardTouchMove;
    };

    const onCardMouseStart = (e) => {
        setInit(false);
        pos3 = e.clientX;
        pos4 = e.clientY;
        target = e;

        e.target.onmouseup = onCardMouseEnd;
        document.addEventListener('mousemove', onCardMouseMove)
    };

    const onCardTouchMove = (e) => {
        pos1 = pos3 - e.touches[0].clientX;
        pos2 = pos4 - e.touches[0].clientY;
        pos3 = e.touches[0].clientX;
        pos4 = e.touches[0].clientY;

        const el = e.target;
        const elNext = e.target.nextSibling;
        if (!success[(count * 10) + Number(e.target.id)]) {
            el.style.top = (el.offsetTop - pos2) + "px";
            el.style.left = (el.offsetLeft - pos1) + "px";
        }

        const leftOk = (elNext.offsetLeft - 20 < el.offsetLeft && el.offsetLeft < elNext.offsetLeft + 20);
        const topOk = (elNext.offsetTop - 20 < el.offsetTop && el.offsetTop < elNext.offsetTop + 20);

        // const offsetRight = window.innerWidth - el.offsetLeft - el.offsetWidth;
        // const rightOk = (10 < offsetRight && offsetRight < 40);
        // const topPosOk = (Number(sortTop[e.target.id].replace('px', '')) - 20 < el.offsetTop && el.offsetTop < Number(sortTop[e.target.id].replace('px', '')) + 20);
        // const topOk = sortTop.map(v => Number(v.replace('px', ''))).find((v) => {
        //     return (v - 20 < el.offsetTop && el.offsetTop < v + 20);
        // }) !== undefined;

        if (leftOk && topOk) {
            success[(count * 10) + Number(e.target.id)] = leftOk && topOk;
            setSuccess([...success]);

            e.target.ontouchend = null;
            e.target.ontouchmove = null;
        } else if (leftOk && !topOk) {
            el.classList.add('fail');
            const time = setTimeout(() => {
                el.classList.remove('fail');
                clearTimeout(time);
            }, 500);
            failSound.play();
            setScore(score - 1);
        }
    };

    const onCardMouseMove = (event) => {
        const e = target;
        pos1 = pos3 - event.clientX;
        pos2 = pos4 - event.clientY;
        pos3 = event.clientX;
        pos4 = event.clientY;

        const el = e.target;
        const elNext = e.target.nextSibling;
        if (!success[(count * 10) + Number(e.target.id)]) {
            el.style.top = (el.offsetTop - pos2) + "px";
            el.style.left = (el.offsetLeft - pos1) + "px";
            el.style.padding = '20px';
            el.style.zIndex = 10;
        }

        const leftOk = (elNext.offsetLeft - 40 < el.offsetLeft && el.offsetLeft < elNext.offsetLeft + 40);
        const topOk = (elNext.offsetTop - 40 < el.offsetTop && el.offsetTop < elNext.offsetTop + 40);

        if (leftOk && topOk) {
            success[(count * 10) + Number(e.target.id)] = leftOk && topOk;
            setSuccess([...success]);

            e.target.onmouseup(e);
        } else if (leftOk && !topOk) {
            el.classList.add('fail');
            const time = setTimeout(() => {
                el.classList.remove('fail');
                clearTimeout(time);
            }, 500);
            failSound.play();
            setScore(score - 1);

            e.target.onmouseup(e);
        }
    };

    const onCardTouchEnd = (e) => {
        e.target.style.top = '';
        e.target.style.left = '';
        e.target.classList.add('fail');
        const time = setTimeout(() => {
            e.target.classList.remove('fail');
            clearTimeout(time);
        }, 500);

        e.target.ontouchmove = null;
    };

    const onCardMouseEnd = (e) => {
        e.target.style.top = '';
        e.target.style.left = '';
        e.target.style.zIndex = '';
        e.target.classList.add('fail');
        const time = setTimeout(() => {
            e.target.classList.remove('fail');
            clearTimeout(time);
        }, 500);

        e.target.onmouseup = null;
        document.removeEventListener('mousemove', onCardMouseMove);
    };

    const doSliceQuestion = useCallback(() => {
        const result = [];

        result.push(question.question.slice(0, 10));
        result.push(question.question.slice(10, 20));
        result.push(question.question.slice(20, 30));
        setSliceQuestion([...result]);
    }, []);

    useEffect(() => {
        doSliceQuestion();
    }, []);

    const posInsert = useCallback(() => {
        const array = [];

        if (sliceQuestion.length > 0) {
            for (let i = 0; i < sliceQuestion[count].length; i++) {
                const pos = `${(i * cardHeight) + 10}px`;
                array.push(pos);
            }

            array.sort(() => Math.random() - 0.5)
            setSortTop([...array]);
        }
    }, [sliceQuestion, count]);

    useEffect(() => {
        posInsert();
    }, [sliceQuestion, count]);

    const onComplete = useCallback(() => {
        if (success.length !== 0) {
            const time = setTimeout(() => {
                dropSound.play();
                clearTimeout(time);
            }, 1000);
        }

        if (success.length === question.question.slice(0, 30).length) {
            if(success.length > 0){
                if (success.map(v => v === false || v === undefined).find(v => v === true) === undefined) {
                    const time = setTimeout(() => {
                        onCompletePageMove(score);
                        clearTimeout(time);
                    }, 2000, score);
                }
            }
        }
    }, [success]);

    useEffect(() => {
        onComplete();
    }, [success]);

    const onStart = useCallback(() => {
        setFirst(false);
        awsPolly.stopSpeak();
    }, []);

    const onPrev = useCallback(() => {
        setInit(true);
        setCount(count - 1);
    }, [count]);

    const onNext = useCallback(() => {
        setInit(true);
        setCount(count + 1);
    }, [count]);

    const onCardClick = useCallback((text) => {
        awsPolly.startSpeak({text, sex: changeSex});
    }, [changeSex]);

    const onChangeAudioSex = useCallback(() => {
        setChangeSex(!changeSex);
    }, [changeSex]);

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', onCardMouseMove);
        }
    }, []);

    if (question.question && sliceQuestion[count]) return (
        <Wrap>
            <QuestionHeader isAudioChangeSex={true} onChangeAudioSex={onChangeAudioSex}/>
            <QuestionMainWrap className={first ? '' : 'hidden'} overflow={'auto'}>
                {
                    question.question.map((item, index) => (
                        <QuestionCardWrap key={item.seq}>
                            <QuestionCard position={'unset'} float={'left'} zIndex={1} id={index}
                                          success={success[index]} onClick={() => onCardClick(item.titleEn)}>
                                {item.titleEn.trim()}
                            </QuestionCard>

                            <QuestionCard position={'unset'} float={'right'} right={'20px'} success={success[index]}>
                                {item.titleKr.trim()}
                            </QuestionCard>
                        </QuestionCardWrap>
                    ))
                }
            </QuestionMainWrap>
            <QuestionButtonWrap className={first ? '' : 'hidden'}>
                <Button shape={'rectangle'} size={'small'} color={mainColor} onClick={onStart}>START</Button>
            </QuestionButtonWrap>

            <QuestionMainWrap className={first ? 'hidden' : ''} overflow={'hidden'}>
                {
                    sliceQuestion[count].map((item, index) => (
                        init && success[(count * 10) + index] ? '' : <QuestionCardWrap key={item.seq}>
                            <QuestionCard position={'absolute'} onTouchStart={onCardTouchStart}
                                          onMouseDown={onCardMouseStart} float={'left'}
                                          top={`${(index * cardHeight) + 10}px`} zIndex={1}
                                          id={index}
                                          success={success[(count * 10) + index]}
                                          onClick={() => onCardClick(item.titleEn)} color={mainColor}>
                                {item.titleEn.trim()}
                            </QuestionCard>

                            <QuestionCard position={'absolute'} float={'right'} top={sortTop[index]} right={'20px'}
                                          success={success[(count * 10) + index]}>
                                {item.titleKr.trim()}
                            </QuestionCard>
                        </QuestionCardWrap>
                    ))
                }
            </QuestionMainWrap>

            <QuestionButtonWrap className={first ? 'hidden' : ''}>
                {count === 0 ? '' :
                    <Button shape={'rectangle'} size={'small'} color={mainColor} onClick={onPrev}>PREV</Button>}
                {count === 2 ? '' :
                    <Button shape={'rectangle'} size={'small'} color={mainColor} onClick={onNext}>NEXT</Button>}
            </QuestionButtonWrap>
        </Wrap>
    );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const QuestionMainWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  gap: 20px;
  overflow: ${({overflow}) => overflow};
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;

  &.hidden {
    display: none;
  }

  @media screen and (max-width: 768px) {
    background-color: #f9f9f9;
  }
`;

const QuestionCardWrap = styled.div`

`;

const QuestionButtonWrap = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px 20px 20px 20px;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;

  &.hidden {
    display: none;
  }
`;

const QuestionCard = styled.div`
  position: ${({position}) => position};
  border-radius: 6px;
  font-size: 24px;
  width: 300px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  float: ${({float}) => float};
  z-index: ${({zIndex}) => zIndex};
  right: ${({right}) => right};
  top: ${({top}) => top};
  animation: ${({success}) => success && 'drop 2s forwards'};

  @media screen and (max-width: 768px) {
    font-size: 14px;
    width: 160px;
    height: 30px;
  }

  @keyframes drop {
    0% {
      transform-origin: center;
      opacity: 1;
    }
    20% {
      transform: translate3d(0, 20px, 0) rotate3d(0, 0, 1, -10deg);
      opacity: 1;
    }
    40%, 45% {
      transform: translate3d(0, -20px, 0) rotate3d(0, 0, 1, 10deg);
      opacity: 1;
    }
    to {
      opacity: 1;
      transform: translate3d(0, 2000px, 0) rotate3d(0, 0, 0, 0deg);
    }
  }

  &.fail {
    top: ${({top}) => top} !important;
    left: 20px !important;
    transition: all 0.2s;
  }

  ${({color}) => {
    switch (color) {
      case 'yellow' :
        return `
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.30), 0 1px 1px rgba(0, 0, 0, .4);
        background: linear-gradient(#ECE3BCFF, #F7CC69FF);
        color: #fff;
        `;
      case 'navy' :
        return `
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.30), 0 1px 1px rgba(0, 0, 0, .4);
        background: linear-gradient(#4faefc, #4169E1FF);
        color: #fff;
        `;
      default:
        return `
        box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
        background-color: #fff;
        `;
    }
  }};
`;

export default Question1;
