import {all, fork} from 'redux-saga/effects';
import login from "./redux/login/saga";
import user from "./redux/user/saga";
import question from "./redux/question/saga";
import setting from "./redux/setting/saga";

export default function* rootSaga() {
    yield all([
        fork(login),
        fork(user),
        fork(question),
        fork(setting),
    ])
}