import produce from "immer";
import {LOAD_SETTING_FAILURE, LOAD_SETTING_REQUEST, LOAD_SETTING_SUCCESS} from "./constants";

const initialState = {
    setting: {},
    loadSettingLoading: false,
    loadSettingDone: false,
    loadSettingError: false,
}

export const loadSettingRequestAction = (data) => {
    return {
        type: LOAD_SETTING_REQUEST,
        data,
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOAD_SETTING_REQUEST:
            draft.loadSettingLoading = true;
            draft.loadSettingDone = false;
            draft.loadSettingError = null;
            draft.setting = {};
            break;
        case LOAD_SETTING_SUCCESS:
            draft.loadSettingLoading = false;
            draft.loadSettingDone = true;
            draft.setting = action.data;
            break;
        case LOAD_SETTING_FAILURE:
            draft.loadSettingLoading = false;
            draft.loadSettingDone = false;
            draft.loadSettingError = action.data;
            break;
    }
});

export default reducer;

