import Button from "../../atoms/button";
import Icon from "../../atoms/icon";
import styled from "styled-components";

const AudioCircleButton = ({onClick, background, iconWidth, iconHeight}) => {
    return (
        <Wrap>
            <Button shape={'circle'} size={'full'} color={'orange'} fontSize={'18px'} maxWidth={'32px'} onClick={onClick}>
                <Icon width={iconWidth} height={iconHeight} margin={'0 auto'} background={background} />
            </Button>
        </Wrap>
    )
};

const Wrap = styled.div`
  width: 32px;
  height: 32px;
`

export default AudioCircleButton;
