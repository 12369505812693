import {
    LOG_IN_CHECK_FAILURE,
    LOG_IN_CHECK_REQUEST,
    LOG_IN_CHECK_SUCCESS,
    LOG_IN_FAILURE,
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS
} from "./constants";
import produce from "immer";

const initialState = {
    me: {},
    logInLoading: false,
    logInDone: false,
    logInError: false,
    logInCheckLoading: false,
    logInCheckDone: false,
    logInCheckError: false,
}

export const loginRequestAction = (data) => {
    return {
        type: LOG_IN_REQUEST,
        data,
    }
}

export const loginCheckRequestAction = (data) => {
    return {
        type: LOG_IN_CHECK_REQUEST,
        data,
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOG_IN_REQUEST:
            draft.logInLoading = true;
            draft.logInDone = false;
            draft.logInError = null;
            break;
        case LOG_IN_SUCCESS:
            draft.logInLoading = false;
            draft.logInDone = true;
            draft.me = action.data;
            break;
        case LOG_IN_FAILURE:
            draft.logInLoading = false;
            draft.logInDone = false;
            draft.logInError = action.data;
            break;
        case LOG_IN_CHECK_REQUEST:
            draft.logInCheckLoading = true;
            draft.logInCheckDone = false;
            draft.logInCheckError = null;
            break;
        case LOG_IN_CHECK_SUCCESS:
            draft.logInCheckLoading = false;
            draft.logInCheckDone = true;
            draft.me = action.data;
            break;
        case LOG_IN_CHECK_FAILURE:
            draft.logInCheckLoading = false;
            draft.logInCheckDone = false;
            draft.logInCheckError = action.data;
            break;
    }
});

export default reducer;

