import Avatar from "../../atoms/avatar";
import styled from "styled-components";
import {useCallback, useState} from "react";
import LocalStorage from "../../../utils/common/localStorage";
import avatar from '../../../assets/images/avatar.png';

const AvatarUpload = ({alt}) => {
    const [src, setSrc] = useState(LocalStorage.getItem('avatar-src') || avatar);

    const onClick = useCallback(() => {

    }, []);

    const onChange = useCallback((e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            setSrc(reader.result);
            LocalStorage.setItem('avatar-src', reader.result);
        };

        reader.readAsDataURL(file);
    }, [src]);

    return (
        <Wrap onClick={onClick}>
            <label htmlFor={'avatar-file'}>
                <Avatar src={src} alt={alt}/>
            </label>
            <HiddenInput type={'file'} id={'avatar-file'} accept={'image/*'} onChange={onChange}/>
        </Wrap>
    );
}

const Wrap = styled.div`

`;

const HiddenInput = styled.input`
  display: none;
`

export default AvatarUpload;