import logo from '../../../assets/images/logo.png';
import Button from "../../atoms/button";
import styled from "styled-components";
import Label from "../../atoms/label";
import history from "../../../utils/history";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Icon from "../../atoms/icon";
import {useCallback, useEffect, useState} from "react";
import LocalStorage from "../../../utils/common/localStorage";
import AvatarUpload from "../../molecules/avatarUpload";
import {TOKEN_KEY_NAME} from "../../../utils/common/constants";
import {loadSettingRequestAction} from "../../../redux/setting/reducer";

const LayoutTemplate = ({me, onChangeColor, mainColor, children}) => {
    const dispatch = useDispatch();
    const question = useSelector(((state) => state.question.question), shallowEqual);

    useEffect(() => {
        if (!me.level) history.push('/login');
    }, [me]);

    const onLogout = useCallback(() => {
        LocalStorage.removeItem(TOKEN_KEY_NAME);
        history.push('/login');
    }, []);

    const loadSetting = useCallback(() => {
        dispatch(loadSettingRequestAction());
    }, []);

    useEffect(() => {
        loadSetting();
    }, []);

    if (me.name) return (
        <Wrap>
            <Background color={mainColor}/>
            <Logo src={logo} alt={'logo'}/>
            <Card align={'center'}>
                <CardMainItemWrap>
                    <AvatarUpload alt={'avatar'}/>

                    <CardMainInfoWrap>
                        <Label fontSize={'24px'}>{me.name}님, 안녕하세요!</Label>
                        <MainCardSubButtonWrap>
                            <Button shape={'oval'} size={'small'} color={mainColor} fontSize={'14px'} maxWidth={'80px'} maxHeight={'30px'}
                                    onClick={onChangeColor}>COLOR</Button>
                            <Button shape={'oval'} size={'small'} color={mainColor} fontSize={'14px'} maxWidth={'80px'} maxHeight={'30px'}
                                    onClick={onLogout}>LOGOUT</Button>
                        </MainCardSubButtonWrap>
                    </CardMainInfoWrap>
                </CardMainItemWrap>

                <CardMainItemWrap>
                    {/*<CardMainInfoItemWrap>*/}
                    {/*    <div>*/}
                    {/*        <Label>State</Label>*/}
                    {/*        <Label fontSize={'24px'} fontWeight={'bold'} color={'rgb(244, 132, 98)'}>*/}
                    {/*            <p>Lv.{me.level}</p>*/}
                    {/*            <p>Unit.{me.unit}</p>*/}
                    {/*        </Label>*/}
                    {/*    </div>*/}

                    {/*    <Icon width={'70px'} height={'70px'} margin={'0 0 0 auto'}*/}
                    {/*          background={'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'70\' height=\'70\' viewBox=\'0 0 70 70\'%3e%3cpath data-name=\'사각형 163\' style=\'fill:transparent\' d=\'M0 0h70v70H0z\'/%3e%3cg data-name=\'그룹 49\'%3e%3cpath data-name=\'패스 566\' d=\'M68.292 443.192h41.724v6.536H68.292z\' transform=\'translate(-54.154 -387.935)\' style=\'fill:%23fff59b\'/%3e%3cg data-name=\'그룹 48\'%3e%3cpath data-name=\'패스 567\' d=\'m158 20.255-6.65 3.045a.893.893 0 0 1-1.26-.914l.839-7.258-4.949-5.379a.893.893 0 0 1 .481-1.48l7.168-1.445 3.591-6.37a.894.894 0 0 1 1.557 0l3.59 6.365 7.168 1.445a.893.893 0 0 1 .481 1.48l-4.949 5.379.839 7.258a.893.893 0 0 1-1.26.914z\' transform=\'translate(-122.999 6)\' style=\'fill:%23fff59b\'/%3e%3c/g%3e%3c/g%3e%3cg data-name=\'그룹 50\'%3e%3cpath data-name=\'패스 568\' d=\'M79.951 273.9h-1.915v-14.866a1.111 1.111 0 0 0-1.111-1.111H64.881v-10.188a1.111 1.111 0 0 0-1.111-1.111H48.354a1.111 1.111 0 0 0-1.111 1.111v5.665H35.2a1.111 1.111 0 0 0-1.111 1.111V273.9h-1.916a1.111 1.111 0 0 0 0 2.223h47.778a1.111 1.111 0 0 0 0-2.223zm-43.639-18.27h10.931v18.27H36.311zm13.154-6.779h13.192V273.9H49.466zM64.881 273.9v-13.755h10.932V273.9z\' transform=\'translate(-21.062 -213.214)\' style=\'fill:%23ffd77b\'/%3e%3cpath data-name=\'패스 569\' d=\'M247.111 337.226a1.111 1.111 0 0 0-1.111 1.111v3.99a1.111 1.111 0 1 0 2.223 0v-3.99a1.111 1.111 0 0 0-1.112-1.111z\' transform=\'translate(-212.111 -293.746)\' style=\'fill:%23ffd77b\'/%3e%3c/g%3e%3c/svg%3e ")'}/>*/}
                    {/*</CardMainInfoItemWrap>*/}

                    <CardMainInfoItemWrap>
                        <div>
                            <Label>Point</Label>
                            <Label fontSize={'24px'} fontWeight={'bold'} color={'rgb(244, 132, 98)'}>{me.point.toLocaleString()}<br/></Label>
                        </div>

                        <Icon width={'70px'} height={'70px'} margin={'0 0 0 auto'}
                              background={'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'70\' height=\'70\' viewBox=\'0 0 70 70\'%3e%3cpath data-name=\'사각형 164\' style=\'fill:transparent\' d=\'M0 0h70v70H0z\'/%3e%3cg data-name=\'그룹 52\' transform=\'translate(-148 -449)\'%3e%3cg data-name=\'타원 20\' transform=\'translate(159 463)\' style=\'stroke-width:2px;stroke:%23ffd77b;fill:none\'%3e%3ccircle cx=\'25\' cy=\'25\' r=\'25\' style=\'stroke:none\'/%3e%3ccircle cx=\'25\' cy=\'25\' r=\'24\' style=\'fill:none\'/%3e%3c/g%3e%3ccircle data-name=\'타원 23\' cx=\'6.5\' cy=\'6.5\' r=\'6.5\' transform=\'translate(203 455)\' style=\'fill:%23fff59b\'/%3e%3cg data-name=\'타원 21\' transform=\'translate(165 469)\' style=\'stroke-width:2px;stroke:%23ffd77b;fill:none\'%3e%3ccircle cx=\'19\' cy=\'19\' r=\'19\' style=\'stroke:none\'/%3e%3ccircle cx=\'19\' cy=\'19\' r=\'18\' style=\'fill:none\'/%3e%3c/g%3e%3ccircle data-name=\'타원 22\' cx=\'15\' cy=\'15\' r=\'15\' transform=\'translate(169 473)\' style=\'fill:%23fff59b\'/%3e%3cg data-name=\'그룹 51\'%3e%3cpath data-name=\'패스 570\' d=\'M2385.5 2267.917v-15\' transform=\'translate(-2205.5 -1771.917)\' style=\'stroke-linecap:round;stroke:%23ffc94e;stroke-width:2px;fill:none\'/%3e%3cg data-name=\'사각형 37\' style=\'stroke:%23ffc94e;stroke-width:2px;fill:none\'%3e%3cpath d=\'M1 0h6a5 5 0 0 1 5 5 5 5 0 0 1-5 5H0V1a1 1 0 0 1 1-1z\' style=\'stroke:none\' transform=\'translate(179 480)\'/%3e%3cpath d=\'M1 1h6a4 4 0 0 1 4 4 4 4 0 0 1-4 4H1V1z\' style=\'fill:none\' transform=\'translate(179 480)\'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e ")'}/>
                    </CardMainInfoItemWrap>
                </CardMainItemWrap>
            </Card>

            <MainItemWrap>
                <MainLeftItemWrap>
                    <Label>나의 정보</Label>

                    {/*<SubCard>*/}
                    {/*    <Label>학원 정보</Label>*/}
                    {/*    <Label>{me.class}</Label>*/}
                    {/*</SubCard>*/}


                    <SubCard>
                        <Label>현재 상태</Label>

                        <Label>
                            {me.level}
                            <br/>
                            Unit.{me.unit}
                        </Label>
                        <Label>
                            {question?.levelList?.length === 0 ? "문제가 등록되지 않았습니다." : ''}
                        </Label>
                    </SubCard>
                </MainLeftItemWrap>

                <MainRightItemWrap>
                    {children}
                </MainRightItemWrap>
            </MainItemWrap>
        </Wrap>
    );
}

const Wrap = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  background-color: #f9f9f9;
  overflow-y: auto;
`;

const Logo = styled.img`
  position: relative;
  z-index: 1;
  width: 120px;
  margin: 0 auto 50px auto;
`;

const Background = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 250px;
  background-color: ${({color}) => {
    switch (color) {
      case 'yellow':
        return 'rgb(247, 204, 105)';
      case 'navy':
        return '#4169E1FF';
    }
  }}
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  height: 170px;
  max-width: 1080px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 12px;
  padding: 40px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
`;

const SubCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
  width: 200px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 12px;
  padding: 20px;

  & label:last-child {
    font-size: 14px;
    font-weight: bold;
    color: rgb(244, 132, 98);
  }

  & label:not(:first-child):not(:last-child) {
    font-size: 17px;
    font-weight: bold;
    color: rgb(244, 132, 98);
  }
`;

const CardMainItemWrap = styled.div`
  display: flex;
  gap: 40px;
`

const CardMainInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CardMainInfoItemWrap = styled.div`
  display: flex;
  align-items: end;
  gap: 20px;
  border-left: 1px solid #eee;
  padding-left: 20px;

  & > div {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
`

const MainCardSubButtonWrap = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
`

const MainItemWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 1080px;
  margin: 20px auto 0 auto;
  gap: 40px;
`

const MainLeftItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > label {
    font-size: 22px;
    font-weight: bold;
  }
`

const MainRightItemWrap = styled.div`
  flex: 1;
`

export default LayoutTemplate;
