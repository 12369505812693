import {unstable_HistoryRouter as HistoryRouter, Routes, Route,} from "react-router-dom";
import Splash from "../splash";
import Login from "../login";
import Home from "../home";
import Question from "../question";
import history from "../../utils/history";
import {TOKEN_KEY_NAME} from "../../utils/common/constants";
import {loginCheckRequestAction} from "../../redux/login/reducer";
import {useDispatch} from "react-redux";
import {useCallback, useEffect} from "react";
import "../../assets/css/common.css";
import QuestionComplete from "../question/questionComplete";
import LocalStorage from "../../utils/common/localStorage";
import QuestionList from "../question/questionList";

const App = () => {
    const dispatch = useDispatch();
    const loginCheck = useCallback(() => {
        const token = LocalStorage.getItem(TOKEN_KEY_NAME);
        if (token) dispatch(loginCheckRequestAction({token}));
    }, []);

    history.listen(() => {
        loginCheck();
    });

    useEffect(() => {
        loginCheck();
    }, []);

    return (
        <HistoryRouter history={history}>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/splash" element={<Splash/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/question" element={<Question/>}/>
                <Route path="/question/complete" element={<QuestionComplete/>}/>
                <Route path="/question/list/:level" element={<QuestionList/>}/>
            </Routes>
        </HistoryRouter>
    );
}

export default App;
