import produce from "immer";
import {LEVEL_UP_FAILURE, LEVEL_UP_REQUEST, LEVEL_UP_SUCCESS} from "./constants";

const initialState = {
    levelUpLoading: false,
    levelUpDone: false,
    levelUpError: false,
}

export const levelUpRequestAction = (data) => {
    return {
        type: LEVEL_UP_REQUEST,
        data,
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LEVEL_UP_REQUEST:
            draft.levelUpLoading = true;
            draft.levelUpDone = false;
            draft.levelUpError = null;
            break;
        case LEVEL_UP_SUCCESS:
            draft.levelUpLoading = false;
            draft.levelUpDone = true;
            break;
        case LEVEL_UP_FAILURE:
            draft.levelUpLoading = false;
            draft.levelUpDone = false;
            draft.levelUpError = action.data;
            break;
    }
});

export default reducer;

