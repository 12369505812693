import styled from "styled-components";

const Icon = ({width, height, background, margin, onClick}) => {
    return (
        <IconWrap background={background} width={width} height={height} margin={margin} onClick={onClick}/>
    )
}

const IconWrap = styled.i`
  display: block;
  width: ${({width}) => width};;
  height: ${({height}) => height};;
  background: ${({background}) => background};
  margin: ${({margin}) => margin};
`;

export default Icon;