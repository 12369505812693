import Icon from "../../atoms/icon";
import Label from "../../atoms/label";
import styled from "styled-components";
import history from "../../../utils/history";
import {useCallback, useEffect, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import AwsPolly from "../../../utils/awsPolly";
import AudioCircleButton from "../../molecules/audioCircleButton";
import getQueryString from "../../../utils/getQueryString";
import logo from "../../../assets/images/logo.png";
import awsPolly from "../../../utils/awsPolly";

const QuestionHeader = ({onPrev, audioText, isAudioSpeed, isAudioChangeSex, onChangeAudioSex, changeSex}) => {
    const {me} = useSelector((state) => state.login);
    const question = useSelector(((state) => state.question.question), shallowEqual);
    const {level, unit} = getQueryString();
    const [audioSpeed, setAudioSpeed] = useState(1);

    const onDefaultPrev = useCallback(() => {
        history.push('/');
    }, []);

    const onChangeAudioSpeed = useCallback(() => {
        setAudioSpeed(audioSpeed === 0.5 ? 1 : 0.5);
    }, [audioSpeed]);

    const onChangeAudioSpeedSlow = useCallback(() => {
        setAudioSpeed(audioSpeed === 1 ? 0.5 : 1);
    }, [audioSpeed]);

    const questionHeaderProgressBar = useCallback(() => {
        const result = [];
        const maxLevel = me.statusLevels?.split(',').filter(i => i === '1').length || 5;

        for (let i = 0; i < maxLevel; i++) {
            result.push(<QuestionHeaderProgressBar active={(unit - 1) >= i} first={i === 0} last={(i + 1) === maxLevel} key={i}/>);
        }

        return result;
    }, [question.maxUnitCount, me.statusLevels]);

    useEffect(() => {
        return () => {
            awsPolly.stopSpeak();
        }
    }, []);

    return (
        <QuestionHeaderWrap>
            <QuestionHeaderTopWrap>
                <Icon width={'24px'} height={'24px'}
                      background={'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'28\' height=\'28\' viewBox=\'0 0 28 28\'%3e%3cpath data-name=\'사각형 32\' style=\'fill:none\' d=\'M0 0h28v28H0z\'/%3e%3cg data-name=\'그룹 46\'%3e%3cpath data-name=\'패스 564\' d=\'M1433.472 1828.926h20\' transform=\'translate(-1431 -1814.926)\' style=\'stroke:%23232323;stroke-linecap:round;stroke-width:1.5px;fill:none\'/%3e%3c/g%3e%3cpath data-name=\'패스 947\' d=\'m27.893 55-8 8 8 8\' style=\'stroke-linejoin:round;stroke:%23232323;stroke-linecap:round;stroke-width:1.5px;fill:none\' transform=\'translate(-18 -49)\'/%3e%3c/svg%3e ")'}
                      onClick={onPrev || onDefaultPrev}/>
                <Label fontSize={'17px'} flex={1} textAlign={'center'}>
                    <LogoWrap src={logo} alt={'logo'}/>
                </Label>

                <AudioControlWrap>
                    {
                        isAudioChangeSex &&
                        <AudioCircleButton
                            onClick={onChangeAudioSex}
                            background={'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23fff\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\' class=\'lucide lucide-audio-waveform\'%3e%3cpath d=\'M2 13a2 2 0 0 0 2-2V7a2 2 0 0 1 4 0v13a2 2 0 0 0 4 0V4a2 2 0 0 1 4 0v13a2 2 0 0 0 4 0v-4a2 2 0 0 1 2-2\'/%3e%3c/svg%3e")'}
                            iconWidth={'24px'}
                            iconHeight={'24px'}
                        />
                    }

                    {
                        isAudioSpeed &&
                        <AudioCircleButton
                            onClick={onChangeAudioSpeedSlow}
                            background={`url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23fff\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\' class=\'lucide lucide-chevron-right\'%3e%3cpath d=\'m9 18 6-6-6-6\'/%3e%3c/svg%3e")`}
                            iconWidth={'24px'}
                            iconHeight={'24px'}
                        />
                    }

                    {
                        isAudioSpeed &&
                        <AudioCircleButton
                            onClick={onChangeAudioSpeed}
                            background={'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23fff\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\' class=\'lucide lucide-chevrons-right\'%3e%3cpath d=\'m6 17 5-5-5-5\'/%3e%3cpath d=\'m13 17 5-5-5-5\'/%3e%3c/svg%3e")'}
                            iconWidth={'24px'}
                            iconHeight={'24px'}
                        />
                    }

                    {
                        audioText &&
                        <AudioCircleButton
                            onClick={() => {
                                AwsPolly.startSpeak({text: audioText, speed: audioSpeed, sex: changeSex});
                            }}
                            background={'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 32 32\'%3e%3cpath data-name=\'사각형 65\' style=\'fill:transparent\' d=\'M0 0h32v32H0z\'/%3e%3cg data-name=\'그룹 72\'%3e%3cpath data-name=\'패스 948\' d=\'M4.168 15.924H1.39A1.39 1.39 0 0 1 0 14.534V6.2a1.389 1.389 0 0 1 1.39-1.391h2.778z\' transform=\'translate(4 5.747)\' style=\'fill:%23fff\'/%3e%3cpath data-name=\'패스 949\' d=\'m13.309 20.04-7.647-4.37V4.555l7.647-4.37a1.389 1.389 0 0 1 2.078 1.207v17.442a1.389 1.389 0 0 1-2.078 1.206\' transform=\'translate(3.702 6)\' style=\'fill:%23fff\'/%3e%3cpath data-name=\'패스 950\' d=\'M21.375 17.25a.75.75 0 0 1-.347-1.415 6.2 6.2 0 0 0 0-11A.75.75 0 1 1 21.72 3.5a7.695 7.695 0 0 1 0 13.665.747.747 0 0 1-.345.085z\' transform=\'translate(2.875 5.781)\' style=\'fill:%23fff\'/%3e%3cpath data-name=\'패스 951\' d=\'M19.207 13.817a.75.75 0 0 1-.309-1.434 2.03 2.03 0 0 0 0-3.7.75.75 0 0 1 .62-1.366 3.53 3.53 0 0 1-.007 6.431.747.747 0 0 1-.304.069z\' transform=\'translate(2.989 5.579)\' style=\'fill:%23fff\'/%3e%3c/g%3e%3c/svg%3e")'}
                            iconWidth={'24px'}
                            iconHeight={'24px'}
                        />
                    }
                </AudioControlWrap>
            </QuestionHeaderTopWrap>

            <QuestionHeaderBottomWrap>
                <QuestionHeaderBottomLabelWrap>{level} / {unit}단계 </QuestionHeaderBottomLabelWrap>
                {questionHeaderProgressBar()}
            </QuestionHeaderBottomWrap>
        </QuestionHeaderWrap>
    );
};


const QuestionHeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;

const QuestionHeaderTopWrap = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: 36px;

  & i {
    cursor: pointer;
  }
`;

const QuestionHeaderBottomWrap = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const QuestionHeaderBottomLabelWrap = styled(Label)`
  position: absolute;
  right: 0;
  bottom: 16px;
  font-size: 18px;
  color: rgb(244, 132, 98);

  @media screen and (max-width: 768px) {
    font-size: 12px;
    bottom: 8px;
  }
`

const QuestionHeaderProgressBar = styled.div`
  height: 12px;
  width: 100%;
  background-color: ${({active}) => active ? 'rgb(244, 132, 98)' : '#ddd'};
  border-top-left-radius: ${({first}) => first ? '6px' : ''};
  border-bottom-left-radius: ${({first}) => first ? '6px' : ''};
  border-top-right-radius: ${({last}) => last ? '6px' : ''};
  border-bottom-right-radius: ${({last}) => last ? '6px' : ''};

  @media screen and (max-width: 768px) {
    height: 6px;
  }
`;

const LogoWrap = styled.img`
  position: relative;
  z-index: 1;
  width: 100px;

  @media screen and (max-width: 768px) {
    width: 70px;
  }
`;

const AudioControlWrap = styled.div`
  display: flex;
  right: 0;
  position: absolute;
  gap: 15px;

  @media screen and (max-width: 768px) {
    gap: 7px;
  }
`

export default QuestionHeader;
