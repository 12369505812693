import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import Question1 from "../../components/templates/question/question1";
import Question2 from "../../components/templates/question/question2";
import Question3 from "../../components/templates/question/question3";
import Question4 from "../../components/templates/question/question4";
import Question5 from "../../components/templates/question/question5";
import {loadQuestionRequestAction} from "../../redux/question/reducer";
import getQueryString from "../../utils/getQueryString";
import {levelUpRequestAction} from "../../redux/user/reducer";
import history from "../../utils/history";

const Question = () => {
    const {me} = useSelector((state) => state.login);
    const {question, loadQuestionDone} = useSelector(((state) => state.question), shallowEqual);
    const dispatch = useDispatch();
    const {manual, level, unit, score, phase} = getQueryString();
    const loadQuestion = useCallback((unit) => {
        dispatch(loadQuestionRequestAction({level, unit: unit, academySeq: !manual ? 0 : me.academySeq, phase: !manual ? me.phase : 0}));
    }, [level, unit, me.academySeq, me.phase]);

    useEffect(() => {
        loadQuestion(unit);
    }, [level, unit, me.academySeq]);

    const onCompletePageMove = useCallback((questionScore) => {
        const totalScore = !score && !manual ? Number(me.score) + questionScore : !score ? questionScore : Number(score) + questionScore;
        const splitLevelStatus = me.statusLevels?.split(",") || [1, 1, 1, 1, 1];

        if (unit === '5') {
            if (Number(phase) === 4 && !manual) {
                dispatch(levelUpRequestAction({unit: 1, seq: me.seq, score: 0, phase: 1, level: me.level}));
            } else if (Number(phase) !== 4 && !manual) {
                dispatch(levelUpRequestAction({unit: 1, seq: me.seq, score: 0, phase: Number(phase) + 1}));
            }
            history.push(`/question/complete?score=${totalScore}&unit=${unit}&level=${level}&manual=${manual}`);
        } else if (splitLevelStatus[Number(unit)] === '0') {
            if (Number(phase) === 4 && !manual) {
                dispatch(levelUpRequestAction({unit: 1, seq: me.seq, score: 0, phase: 1, level: me.level}));
            } else if (Number(phase) !== 4 && !manual) {
                dispatch(levelUpRequestAction({unit: 1, seq: me.seq, score: 0, phase: Number(phase) + 1}));
            }
            history.push(`/question/complete?score=${totalScore}&unit=${unit}&level=${level}&manual=${manual}`);
        } else {
            if (!manual) dispatch(levelUpRequestAction({
                unit: me.unit !== '1' ? Number(unit) + 1 : unit,
                seq: me.seq,
                score: totalScore,
                phase: Number(phase)
            }));
            const newUnit = Number(unit) + 1;
            dispatch(loadQuestionRequestAction({level, unit: newUnit, academySeq: !manual ? 0 : me.academySeq, phase}));
            history.push(`/question?level=${level}&unit=${newUnit}&manual=${manual}&phase=${manual ? 0 : phase}&score=${totalScore}`);
        }
    }, [level, unit, me.statusLevels, me.academySeq, phase, me.level]);

    if (question?.question) {
        // if (loadQuestionDone && question.question.length === 0) {
        //     dispatch(loadQuestionRequestAction({level, unit: '1', academySeq: !manual ? 0 : me.academySeq}));
        //     alert('문제가 등록 되지않았습니다.');
        //     history.push('/');
        // }

        return (
            <>
                {
                    unit === '1' && <Question1 onCompletePageMove={onCompletePageMove}/>
                }

                {
                    unit === '2' && <Question2 onCompletePageMove={onCompletePageMove}/>
                }

                {
                    unit === '3' && <Question3 onCompletePageMove={onCompletePageMove}/>
                }

                {
                    unit === '4' && <Question4 onCompletePageMove={onCompletePageMove}/>
                }

                {
                    unit === '5' && <Question5 onCompletePageMove={onCompletePageMove}/>
                }
            </>
        )
    }
}

export default Question;
