import styled from "styled-components";

const Avatar = ({src, alt}) => {
    return (
        <AvatarWrap src={src} alt={alt}/>
    )
}

const AvatarWrap = styled.img`
  width: 94px;
  height: 94px;
  border-radius: 100%;
`;

export default Avatar;
