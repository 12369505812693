import HomeMobileTemplate from "../../components/templates/home/mobile";
import mobileApp from "../../utils/common/mobileApp";
import HomePcTemplate from "../../components/templates/home/pc";

const Home = () => {
    return (
        <>
            {
                mobileApp.isMobileWeb() ? <HomeMobileTemplate/> : <HomePcTemplate/>
            }
        </>
    );
}

export default Home;
