import styled from "styled-components";

const Button = ({children, onClick, ...props}) => {
    return (
        <ButtonWrap onClick={onClick} {...props}>
            {children}
        </ButtonWrap>
    )
};

const ButtonWrap = styled.button`
  max-width: ${({maxWidth}) => maxWidth };
  max-height: ${({maxHeight}) => maxHeight };
  min-width: ${({minWidth}) => minWidth };
  min-height: ${({minHeight}) => minHeight };
  width: 100%;
  height: ${({size}) => {
    switch (size) {
      case 'small':
        return '45px';
      case 'middle':
        return '60px';
      case 'full':
        return '100%';
    }
  }};
  margin: ${({margin}) => margin};
  font-size: ${({fontSize}) => fontSize};
  background-color: ${({color}) => {
    switch (color) {
      case 'orange':
        return 'rgb(244, 132, 98)';
      case 'yellow':
        return 'rgb(247, 204, 105)';
      case 'navy':
        return '#4169E1FF';
      case 'white':
        return '#fff';
    }
  }};
  border-radius: ${({shape}) => {
    switch (shape) {
      default:
      case 'rectangle':
        return '6px;';
      case 'oval':
        return '23px;';
      case 'circle':
        return '100%;';
    }
  }};
  color: ${({color}) => {
    switch (color) {
      case 'orange':
      case 'yellow':
      case 'navy':
        return '#fff';
      case 'white':
        return 'rgb(244, 132, 98)';
    }
  }};
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
`;

export default Button;
