import axios from "axios";
import {
    LOG_IN_CHECK_FAILURE,
    LOG_IN_CHECK_REQUEST,
    LOG_IN_CHECK_SUCCESS,
    LOG_IN_FAILURE,
    LOG_IN_REQUEST,
    LOG_IN_SUCCESS
} from "./constants";
import {all, fork, takeLatest, call, put} from 'redux-saga/effects';
import {TOKEN_KEY_NAME} from "../../utils/common/constants";
import history from "../../utils/history";
import LocalStorage from "../../utils/common/localStorage";

function logInAPI(data) {
    return axios.post('/user/login', data)
}

function logInCheckAPI(data) {
    return axios.post('/user/login/check', data)
}

function* logIn(action) {
    try {
        const result = yield call(logInAPI, action.data);
        if (result.data.token) LocalStorage.setItem(TOKEN_KEY_NAME, result.data.token);
        yield put({
            type: LOG_IN_SUCCESS,
            data: result.data,
        });
        history.push('/');
    } catch (err) {
        yield put({
            type: LOG_IN_FAILURE,
            data: err.response.data,
        });
        alert('아이디 또는 비밀번호가 올바르지 않습니다.');
    }
}

function* logInCheck(action) {
    try {
        const result = yield call(logInCheckAPI, action.data);
        yield put({
            type: LOG_IN_CHECK_SUCCESS,
            data: result.data,
        });

        if (history.location.pathname.indexOf('splash') !== -1) {
            const time = setTimeout(() => {
                history.push('/');
                clearTimeout(time);
            }, 2000);
        }
    } catch (err) {
        yield put({
            type: LOG_IN_CHECK_FAILURE,
            data: err.response.data,
        });

        if (history.location.pathname.indexOf('splash') !== -1) {
            const time = setTimeout(() => {
                history.push('/login');
                clearTimeout(time);
            }, 2000);
        }
    }
}

function* watchLogIn() {
    yield takeLatest(LOG_IN_REQUEST, logIn);
}

function* watchLogInCheck() {
    yield takeLatest(LOG_IN_CHECK_REQUEST, logInCheck);
}

export default function* userSaga() {
    yield all([
        fork(watchLogIn),
        fork(watchLogInCheck)
    ])
}

