import LayoutTemplate from "../layout";
import {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import LocalStorage from "../../../utils/common/localStorage";
import Label from "../../atoms/label";
import Button from "../../atoms/button";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {loadQuestionLevelListRequestAction} from "../../../redux/question/reducer";
import history from "../../../utils/history";

const HomePcTemplate = () => {
    const me = useSelector(((state) => state.login.me), shallowEqual);
    const [mainColor, setMainColor] = useState(LocalStorage.getItem('mainColor') || 'yellow');

    const onChangeColor = useCallback(() => {
        const tempColor = mainColor === 'yellow' ? 'navy' : 'yellow';
        setMainColor(tempColor);
        LocalStorage.setItem('mainColor', tempColor);
    }, [mainColor]);

    const onTestExam = useCallback(() => {
        history.push(`/question?level=${me.testLevel}&phase=0&unit=1&manual=true`);
    }, [me.testLevel, me.unit, me.phase]);

    const onExam = useCallback(() => {
        history.push(`/question?level=${me.level}&unit=${me.unit}&phase=${me.phase}&manual=false`);
    }, [me.level, me.unit, me.phase]);

    return (
        <LayoutTemplate me={me} onChangeColor={onChangeColor} mainColor={mainColor}>
            <QuizListWrap>
                <Label>시험 대비</Label>
                <Button size={'small'} color={mainColor} fontSize={'18px'} onClick={onTestExam}>START</Button>

                <Label>A<strong>O</strong>NE</Label>
                <Button size={'small'} color={mainColor} fontSize={'18px'} onClick={onExam}>START</Button>

                <Label>Writing Coach</Label>
                <Button size={'small'} color={mainColor} fontSize={'18px'} onClick={() => window.open('https://culp.cloubot.com/login')}>MOVE</Button>
            </QuizListWrap>
        </LayoutTemplate>
    );
}

const QuizListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > label {
    font-size: 22px;
    font-weight: bold;
  }

  & strong {
    color: orange;
  }
`


export default HomePcTemplate;
