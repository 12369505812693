import styled from "styled-components";
import Label from "../../atoms/label";
import Icon from "../../atoms/icon";
import Button from "../../atoms/button";
import {useCallback, useState} from "react";
import history from "../../../utils/history";
import {useParams} from "react-router-dom";
import LocalStorage from "../../../utils/common/localStorage";
import logo from "../../../assets/images/logo.png";

const QuestionListTemplate = ({me, setting, question}) => {
    const {level} = useParams();
    const [mainColor] = useState(LocalStorage.getItem('mainColor') || 'yellow');

    const loadQuestionListLevel = useCallback(() => {
        const result = [];
        const maxLevel = setting.statusLevel5 === 0 ? 4 : 5

        for (let i = 0; i < maxLevel; i++) {
            result.push(<Button shape={'oval'} size={'small'} color={mainColor} fontSize={'14px'} maxWidth={'60px'} maxHeight={'30px'} key={i} onClick={() => {onQuestionList(`${i + 1}`) }}>{i + 1} 단계</Button>);
        }

        return result;
    }, []);

    const loadQuestionListUnit = useCallback(() => {
        const result = [];

        question.levelList.forEach(v => {
            result.push(<UnitButton shape={'oval'} size={'small'} color={'white'} fontSize={'14px'} key={v} active={true} onClick={() => {onQuestion(level, (v))}}>Unit. {v}</UnitButton>);
        });

        return result;
    }, [level, question]);

    const onPrev = useCallback(() => {
        history.push('/');
    }, []);

    const onQuestionList = useCallback((level) => {
        history.push(`/question/list/${level}`);
    }, []);

    const onQuestion = useCallback((level, unit) => {
        history.push(`/question?manual=true&level=${level}&unit=${unit}`);
    }, []);

    return (
        <QuestionListWrap>
            <QuestionListHeaderWrap>
                <Icon width={'24px'} height={'24px'} onClick={onPrev}
                      background={'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'28\' height=\'28\' viewBox=\'0 0 28 28\'%3e%3cpath data-name=\'사각형 32\' style=\'fill:none\' d=\'M0 0h28v28H0z\'/%3e%3cg data-name=\'그룹 46\'%3e%3cpath data-name=\'패스 564\' d=\'M1433.472 1828.926h20\' transform=\'translate(-1431 -1814.926)\' style=\'stroke:%23232323;stroke-linecap:round;stroke-width:1.5px;fill:none\'/%3e%3c/g%3e%3cpath data-name=\'패스 947\' d=\'m27.893 55-8 8 8 8\' style=\'stroke-linejoin:round;stroke:%23232323;stroke-linecap:round;stroke-width:1.5px;fill:none\' transform=\'translate(-18 -49)\'/%3e%3c/svg%3e ")'}/>
                <Label fontSize={'17px'} flex={1} textAlign={'center'}>
                    <LogoWrap src={logo} alt={'logo'} />
                </Label>
            </QuestionListHeaderWrap>

            <QuestionListLevelWrap>
                { loadQuestionListLevel() }
            </QuestionListLevelWrap>

            <QuestionListUnitWrap>
                { loadQuestionListUnit() }
            </QuestionListUnitWrap>
        </QuestionListWrap>
    );
};

const LogoWrap = styled.img`
  position: relative;
  z-index: 1;
  width: 90px;
`;

const QuestionListWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const QuestionListHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  position: sticky;
  top: 0;
  background-color: #fff;
`

const QuestionListLevelWrap = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 20px 10px 20px;
  justify-content: center;
  position: sticky;
  top: 66.5px;
  background-color: #fff;
`

const QuestionListUnitWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  flex: 1;
`

const UnitButton = styled(Button)`
    opacity: ${({active}) => active ? '1' : '0.5'};
`

export default QuestionListTemplate;
