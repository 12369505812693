import {combineReducers} from "redux";
import login from "./redux/login/reducer";
import user from "./redux/user/reducer";
import question from "./redux/question/reducer";
import setting from "./redux/setting/reducer";

const rootReducer = (state, action) => {
    switch (action.type) {
        default: {
            const combinedReducer = combineReducers({
                login,
                user,
                question,
                setting
            });
            return combinedReducer(state, action);
        }
    }
};

export default rootReducer;