import QuestionHeader from "../../oraganisms/question/questionHeader";
import styled from "styled-components";
import Button from "../../atoms/button";
import {useCallback, useState} from "react";
import history from "../../../utils/history";
import {shallowEqual, useSelector} from "react-redux";
import Input from "../../atoms/input";
import useInput from "../../../hooks/useInput";
import LocalStorage from "../../../utils/common/localStorage";
import Label from "../../atoms/label";

const Question5 = ({onCompletePageMove}) => {
    const question = useSelector(((state) => state.question.question), shallowEqual);
    const [mainColor, setMainColor] = useState(LocalStorage.getItem('mainColor') || 'yellow');
    const [userAnswer, onChangeUserAnswer, setUserAnswer] = useInput('');
    const [userHtmlAnswer, setUserHtmlAnswer] = useState([]);
    const [answer, setAnswer] = useState([]);
    const [fail, setFail] = useState(true);
    const [colorChange, setColorChange] = useState(null)
    const [score, setScore] = useState(100);
    const [count, setCount] = useState(0);

    const onPrev = useCallback(() => {
        history.push('/');
    }, []);

    const handleChangeAnswer = useCallback((e) => {
        let answerText = e.target.value;

        const splitText = answerText.split(" ");
        const splitQuestion = question.question[count].answer.split(" ");
        const tags = [];

        for (let i = 0; i < splitText.length; i++) {
            if (splitText[i] === splitQuestion[i]) {
                tags.push(`<span style="color: blue">${splitText[i]}</span>`);
            } else {
                const specialChars = /[$&+,:;=?@#|'<>.^*()%!-]/g;
                const foundChars = splitQuestion[i].match(specialChars);

                if (foundChars) {
                    foundChars.forEach((specialChar) => {
                        const index = question.question[count].answer.indexOf(specialChar);
                        const sliceQuestion = question.question[count].answer.slice(0, index);
                        if (answerText === sliceQuestion && e.nativeEvent.inputType === 'deleteContentBackward') {
                            splitText[i] = splitText[i].slice(0, -1);
                            answerText = answerText.slice(0, -1);
                        }

                        if (answerText === sliceQuestion) {
                            splitText[i] = splitText[i] + specialChar;
                            answerText = answerText + specialChar;
                            tags.push(`<span style="color: blue">${splitText[i]}</span>`);
                        } else {
                            tags.push(`<span style="color: red">${splitText[i]}</span>`);
                        }
                    });
                } else {
                    tags.push(`<span style="color: red">${splitText[i]}</span>`);
                }
            }
        }

        setUserAnswer(answerText);
        setUserHtmlAnswer(tags.join(" "));
    }, [count, question.question[count]]);

    const onFocus = useCallback(() => {
        document.getElementById("answer").focus()
    }, [])

    const onSubmit = useCallback(() => {
        if (userAnswer.replaceAll('“', '"').replaceAll('”', '"') === question.question[count].answer) {
            if ((count + 1) === question.question.length) {
                onCompletePageMove(score);
            } else {
                setCount(count + 1);
            }
        } else {
            setFail(false);
            setMainColor('orange');
            const time = setTimeout(() => {
                setMainColor('yellow');
                setFail(true);
                clearTimeout(time);
            }, 1000);

            setScore(score - 1);
        }

        setUserAnswer('');
        setUserHtmlAnswer('');
    }, [userAnswer, answer, score]);

    return (
        <Wrap>
            <QuestionHeader onPrev={onPrev}/>
            <QuestionMainWrap>
                <QuestionCard>
                    <Label fontSize={'15px'} color={'#bbb'}>{question.question[count].titleKr}</Label>
                    <Input id={'answer'} theme={'longQuestion'} value={userAnswer} onChange={handleChangeAnswer}/>
                    <Label dangerouslySetInnerHTML={{__html: userHtmlAnswer}} onClick={onFocus}/>
                    <Label fontSize={'14px'} color={'#bbb'} margin={'10px 0'}> {count + 1} / {question.question.length}</Label>
                </QuestionCard>

                <ButtonWrap className={!fail && 'shake'} size={'middle'} color={mainColor} fontSize={'18px'} margin={'20px 0 0 0'}
                            onClick={onSubmit}>NEXT</ButtonWrap>
            </QuestionMainWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const QuestionMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
`;

const QuestionCard = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  height: 400px;
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;

  & input, label {
    font-size: 24px;
  }

  & > .blue {
    color: blue;
  }

  & > input {
    color: #fff;
  }


  & > .orange {
    color: orange;
  }

  & label:nth-child(3) {
    width: 100%;
    top: 44%;
    left: 20px;
    position: absolute;
  }

  @media screen and (max-width: 768px) {
    height: 200px;

    & input, label {
      font-size: 14px;
    }
`;

const ButtonWrap = styled(Button)`
  font-size: 18px;
  max-width: 1080px;
  width: 100%;
  margin: 20px auto 0 auto;

  &.shake {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`

export default Question5;
