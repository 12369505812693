import useInput from "../../hooks/useInput";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {loginRequestAction} from "../../redux/login/reducer";
import styled from "styled-components";
import Button from "../../components/atoms/button";
import Input from "../../components/atoms/input";

const Login = () => {
    const dispatch = useDispatch();
    const [email, onChangeEmail, setEmail] = useInput('');
    const [password, onChangePassword, setPassword] = useInput('');

    const onLogin = useCallback(() => {
        dispatch(loginRequestAction({email, password, role: 'student'}));
    }, [email, password]);

    return (
        <Wrap>
            <LoginWrap>
                <LoginLabel>LOGIN</LoginLabel>
                <LoginInputWrap>
                    <Input type={"email"} theme={'normal'} value={email} onChange={onChangeEmail} placeholder={'아이디를 입력해주세요.'}/>
                    <Input type={"password"} theme={'normal'} value={password} onChange={onChangePassword} placeholder={'패스워드를 입력해주세요.'}/>
                </LoginInputWrap>
                <Button size={'small'} color={'orange'} onClick={onLogin}>로그인</Button>
            </LoginWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgb(247, 204, 105);
`

const LoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 300px;
  height: 250px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  padding: 15px;
`;

const LoginLabel = styled.label`
  font-size: 28px;
  font-weight: bold;
  color: rgb(244, 132, 98);
`;

const LoginInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
`;

export default Login;