import Confetti from "react-confetti";
import Label from "../../atoms/label";
import Button from "../../atoms/button";
import styled from "styled-components";
import {useCallback, useEffect, useState} from "react";
import history from "../../../utils/history";
import getQueryString from "../../../utils/getQueryString";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import completeSoundFile from "../../../assets/sounds/complete.mp3";

const QuestionCompleteTemplate = () => {
    const dispatch = useDispatch();
    const me = useSelector(((state) => state.login), shallowEqual);
    const setting = useSelector(((state) => state.setting.setting), shallowEqual);
    const {score, level, unit, manual} = getQueryString();
    const [completeSound] = useState(new Audio(completeSoundFile));
    // const totalScore = Number(score) / Number(unit);

    const totalScore = useCallback(() => {
        if (Number(score) <= 100) {
            return (Number(score) / 1).toFixed(0)
        } else if (Number(score) <= 200) {
            return (Number(score) / 2).toFixed(0)
        } else if (Number(score) <= 300) {
            return (Number(score) / 3).toFixed(0)
        } else if (Number(score) <= 400) {
            return (Number(score) / 4).toFixed(0)
        } else {
            return (Number(score) / 5).toFixed(0)
        }
    }, [])

    const onHomeMove = useCallback(() => {
        completeSound.pause();
        history.push('/');
    }, []);

    const onNextMove = useCallback(() => {
        history.push(`/question?level=1&unit=${Number(unit) + 1}&manual=${manual}`);
    }, []);

    const scoreText = useCallback(() => {
        if (90 < totalScore) {
            return '완벽해요!';
        } else if (70 < totalScore) {
            return '훌륭해요!';
        } else {
            return '다시 도전해봐요!';
        }
    }, [totalScore]);

    useEffect(() => {
        completeSound.play();
    }, []);

    return (
        <>
            <QuestionCompleteCardWrap>
                <Confetti/>
                <QuestionCompleteMainWrap>
                    <Label> Unit.{unit} </Label>
                    <Label> Complete! </Label>
                </QuestionCompleteMainWrap>

                <QuestionCompleteScoreWrap score={totalScore}>
                    <Label> {totalScore()} </Label>
                    <Label>{scoreText()}</Label>
                </QuestionCompleteScoreWrap>
            </QuestionCompleteCardWrap>

            <QuestionCompleteButtonWrap>
                <Button size={'middle'} color={'white'} onClick={onHomeMove}>HOME</Button>
                {(setting.limitScore < totalScore) || manual && <Button size={'middle'} color={'white'} onClick={onNextMove}>NEXT</Button>}
            </QuestionCompleteButtonWrap>
        </>
    );
}

const QuestionCompleteCardWrap = styled.div`
  background-color: #fff;
  border-radius: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
  gap: 20px;
`;

const QuestionCompleteMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & label:first-child {
    font-size: 48px;
    font-weight: bold;
  }

  & label:last-child {
    font-size: 24px;
  }

  @media screen and (max-width: 768px) {
    & label:first-child {
      font-size: 24px;
      font-weight: bold;
    }

    & label:last-child {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

const QuestionCompleteScoreWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  align-items: center;
  margin-top: 25px;
  background-color: ${({score}) => {
    if (90 < score) {
      return '#00c09d';
    } else if (70 < score) {
      return '#6495edff';
    } else {
      return '#ff4500ff';
    }
  }};
  width: 20vw;
  height: 20vw;
  border-radius: 100%;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;

  & label:first-child {
    font-size: 100px;
    font-weight: bold;
  }

  & label:last-child {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    width: 40vw;
    height: 40vw;

    & label:first-child {
      font-size: 42px;
      font-weight: bold;
    }

    & label:last-child {
      font-size: 14px;
    }
  }
`;

const QuestionCompleteButtonWrap = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
`;

export default QuestionCompleteTemplate;
