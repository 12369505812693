import styled from "styled-components";

const Input = ({onChange, ...props}) => {
    return (
        <ThemeInputWrap onChange={onChange} {...props} autoComplete={'off'}/>
    )
}

const InputWrap = styled.input`
  border: 0;
  font-size: 14px;
`;

const ThemeInputWrap = styled(InputWrap)`
  ${({theme}) => {
    switch (theme) {
      case 'normal':
        return `
            width: 100%;
            padding: 14px;
        `;
      case 'shortQuestion':
        return `
            width: 75px;
            margin: 5px;
            border-bottom: 1px solid #bbb;
            font-weight: bold;
        `;
      case 'longQuestion':
        return `
            width: 100%;
            margin: 5px;
            border-bottom: 1px solid #bbb;
            font-weight: bold;
        `;
      default:
        return;
    }
  }};
`

export default Input;