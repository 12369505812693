import QuestionListTemplate from "../../../components/templates/question/questionList";
import styled from "styled-components";
import {useCallback, useEffect} from "react";
import {loadQuestionLevelListRequestAction} from "../../../redux/question/reducer";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const QuestionList = () => {
    const me = useSelector(((state) => state.login.me), shallowEqual);
    const {level} = useParams();
    const dispatch = useDispatch();
    const setting = useSelector(((state) => state.setting.setting), shallowEqual);
    const question = useSelector(((state) => state.question.question), shallowEqual);

    const loadQuestionLevelList = useCallback(() => {
        dispatch(loadQuestionLevelListRequestAction({level,  academySeq: me.academySeq}));
    }, [level, me.academySeq]);

    useEffect(() => {
        loadQuestionLevelList();
    }, [level, me.academySeq]);

    return (
        <Wrap>
            {
                question.levelList && setting && me && <QuestionListTemplate me={me} setting={setting} question={question}/>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
  width: 100%;
  height: 100vh;
`

export default QuestionList;
