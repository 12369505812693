export const LOAD_QUESTION_REQUEST = 'LOAD_QUESTION_REQUEST';
export const LOAD_QUESTION_SUCCESS = 'LOAD_QUESTION_SUCCESS';
export const LOAD_QUESTION_FAILURE = 'LOAD_QUESTION_FAILURE';

export const LOAD_QUESTION_MAX_UNIT_COUNT_REQUEST = 'LOAD_QUESTION_MAX_UNIT_COUNT_REQUEST';
export const LOAD_QUESTION_MAX_UNIT_COUNT_SUCCESS = 'LOAD_QUESTION_MAX_UNIT_COUNT_SUCCESS';
export const LOAD_QUESTION_MAX_UNIT_COUNT_FAILURE = 'LOAD_QUESTION_MAX_UNIT_COUNT_FAILURE';

export const LOAD_QUESTION_LEVEL_LIST_REQUEST = 'LOAD_QUESTION_LEVEL_LIST_REQUEST';
export const LOAD_QUESTION_LEVEL_LIST_SUCCESS = 'LOAD_QUESTION_LEVEL_LIST_SUCCESS';
export const LOAD_QUESTION_LEVEL_LIST_FAILURE = 'LOAD_QUESTION_LEVEL_LIST_FAILURE';