import axios from "axios";
import {all, fork, takeLatest, call, put} from 'redux-saga/effects';
import {
    LOAD_QUESTION_FAILURE,
    LOAD_QUESTION_LEVEL_LIST_FAILURE, LOAD_QUESTION_LEVEL_LIST_REQUEST,
    LOAD_QUESTION_LEVEL_LIST_SUCCESS,
    LOAD_QUESTION_MAX_UNIT_COUNT_FAILURE,
    LOAD_QUESTION_MAX_UNIT_COUNT_REQUEST,
    LOAD_QUESTION_MAX_UNIT_COUNT_SUCCESS,
    LOAD_QUESTION_REQUEST,
    LOAD_QUESTION_SUCCESS
} from "./constants";

function loadQuestionLevelListAPI(data) {
    return axios.get(`/question/level/list/${data.level}/${data.unit}/${data.academySeq}`)
}

function loadQuestionMaxUnitCountAPI(data) {
    return axios.get(`/question/unit/count/${data.level}/${data.academySeq}`)
}

function loadQuestionAPI(data) {
    return axios.get(`/question/${data.level}/${data.unit}/${data.academySeq}/${data.phase}`)
}

function* loadQuestionLevelList(action) {
    try {
        const result = yield call(loadQuestionLevelListAPI, action.data);
        yield put({
            type: LOAD_QUESTION_LEVEL_LIST_SUCCESS,
            data: result.data.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_QUESTION_LEVEL_LIST_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadQuestionMaxUnitCount(action) {
    try {
        const result = yield call(loadQuestionMaxUnitCountAPI, action.data);
        yield put({
            type: LOAD_QUESTION_MAX_UNIT_COUNT_SUCCESS,
            data: result.data.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_QUESTION_MAX_UNIT_COUNT_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadQuestion(action) {
    try {
        const result = yield call(loadQuestionAPI, action.data);
        yield put({
            type: LOAD_QUESTION_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_QUESTION_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadQuestion() {
    yield takeLatest(LOAD_QUESTION_REQUEST, loadQuestion);
}

function* watchLoadQuestionMaxUnitCount() {
    yield takeLatest(LOAD_QUESTION_MAX_UNIT_COUNT_REQUEST, loadQuestionMaxUnitCount);
}

function* watchLoadQuestionLevelList() {
    yield takeLatest(LOAD_QUESTION_LEVEL_LIST_REQUEST, loadQuestionLevelList);
}

export default function* userSaga() {
    yield all([
        fork(watchLoadQuestion),
        fork(watchLoadQuestionMaxUnitCount),
        fork(watchLoadQuestionLevelList),
    ])
}

