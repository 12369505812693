import {useCallback, useEffect} from "react";
import {TOKEN_KEY_NAME} from "../../utils/common/constants";
import logo from '../../assets/images/logo.png';
import styled from "styled-components";
import LocalStorage from "../../utils/common/localStorage";
import history from "../../utils/history";

const Splash = () => {
    const initLogout = useCallback(() => {
        LocalStorage.removeItem(TOKEN_KEY_NAME);

        const time = setTimeout(() => {
            history.push('/login');
            clearTimeout(time);
        }, 2000);
    }, []);

    useEffect(() => {
        initLogout();
    }, []);

    // const dispatch = useDispatch();
    // const loginCheck = useCallback(() => {
    //     const token = LocalStorage.getItem(TOKEN_KEY_NAME);
    //     if (token) {
    //         dispatch(loginCheckRequestAction({token}));
    //     } else {
    //         setTimeout(() => {
    //             history.push('/login')
    //         }, 2000);
    //     }
    // }, []);

    // useEffect(() => {
    //     loginCheck();
    // }, []);

    return (
        <Wrap>
            <img src={logo} alt={'logo'} width={'120px'}/>
        </Wrap>
    )
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

export default Splash;
